export const isNullOrUndefined = (value) => {
  return value === undefined || value === null
}
const specialConditions = []

export const checkHideCondition = (question, answers) => {
  let onlyInverse = false
  if (question.hide_conditions && question.hide_conditions.length && !specialConditions.includes(question.code)) {
    onlyInverse = true
    for (var condition of question.hide_conditions) {
      if (condition.inverse) {
        if (condition.numeric_type) {
          if (condition.numeric_type === 2) {
            if (
              parseFloat(answers[condition.category_condition][condition.question_condition].answer) <
              parseInt(condition.answer_condition)
            ) {
              return true
            }
          }
          if (
            parseFloat(answers[condition.category_condition][condition.question_condition].answer) >
            parseInt(condition.answer_condition)
          ) {
            return true
          }
        } else {
          if (isNullOrUndefined(answers[condition.category_condition][condition.question_condition].answer)) {
            return false
          }
          if (answers[condition.category_condition][condition.question_condition].answer === condition.answer_condition) {
            return true
          }
          if (
            condition.multiple &&
            (answers[condition.category_condition][condition.question_condition].answer.length === 0 ||
              answers[condition.category_condition][condition.question_condition].answer.includes(condition.answer_condition))
          ) {
            return true
          }
        }
      } else {
        onlyInverse = false
        if (condition.numeric_type) {
          if (condition.numeric_type === 2) {
            if (
              parseFloat(answers[condition.category_condition][condition.question_condition].answer) <
              parseInt(condition.answer_condition)
            ) {
              return true
            }
          }
          if (
            parseFloat(answers[condition.category_condition][condition.question_condition].answer) >
            parseInt(condition.answer_condition)
          ) {
            return false
          }
        } else {
          if (
            isNullOrUndefined(answers[condition.category_condition][condition.question_condition].answer) ||
            answers[condition.category_condition][condition.question_condition].answer === condition.answer_condition
          ) {
            return false
          }
          if (
            condition.multiple &&
            (answers[condition.category_condition][condition.question_condition].answer.length === 0 ||
              (answers[condition.category_condition][condition.question_condition].answer.length === 1 &&
                answers[condition.category_condition][condition.question_condition].answer.includes(
                  condition.answer_condition
                )))
          ) {
            return false
          }
        }
      }
    }
  } else if (question.hide_conditions && question.hide_conditions.length) {
    if (question.code === '33.4') {
      return (
        answers.outra_observacao.oleo_substituto_leite.answer === 0 || answers.outra_observacao.oleo_substituto_leite.answer === 2
      )
    }
    if (question.code === '33.5') {
      return (
        !isNullOrUndefined(answers.outra_observacao.oleo_substituto_leite.answer) &&
        answers.outra_observacao.oleo_substituto_leite.answer !== 2 &&
        !isNullOrUndefined(answers.outra_observacao.certificacao_substituto_leite.answer) &&
        answers.outra_observacao.certificacao_substituto_leite.answer !== 0
      )
    }
    if (question.code === '33.9') {
      return (
        answers.outra_observacao.oleo_concentrado_energetico.answer === 0 ||
        answers.outra_observacao.oleo_concentrado_energetico.answer === 2
      )
    }
    if (question.code === '33.10') {
      return (
        !isNullOrUndefined(answers.outra_observacao.oleo_concentrado_energetico.answer) &&
        answers.outra_observacao.oleo_concentrado_energetico.answer !== 2 &&
        !isNullOrUndefined(answers.outra_observacao.certificacao_concentrado_energetico.answer) &&
        answers.outra_observacao.certificacao_concentrado_energetico.answer !== 0
      )
    }
    if (question.code === '33.14') {
      return (
        answers.outra_observacao.soja_concentrado_proteico.answer === 0 ||
        answers.outra_observacao.soja_concentrado_proteico.answer === 2
      )
    }
    if (question.code === '33.15') {
      return (
        !isNullOrUndefined(answers.outra_observacao.soja_concentrado_proteico.answer) &&
        answers.outra_observacao.soja_concentrado_proteico.answer !== 2 &&
        !isNullOrUndefined(answers.outra_observacao.certificacao_concentrado_proteico.answer) &&
        answers.outra_observacao.certificacao_concentrado_proteico.answer !== 0
      )
    }
  }
  return !onlyInverse
}
